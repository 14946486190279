.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

img {
  max-width: 75vw;
  height: 50vh;
  padding: 0 15px;
  object-fit: cover;
}

img:last-of-type {
  padding-left: 0;
}

.inner {
  /* position: relative; */
  width: 100%;
  overflow: hidden;
  height: 50vh;
}

.wrapper {
  position: absolute;
  display: flex;
}

section {
  display: flex;
  animation: swipe 25500ms linear infinite backwards;
  -webkit-animation: swipe 25500ms linear infinite backwards;
  -moz-animation: swipe 25500ms linear infinite backwards;
  -o-animation: swipe 25500ms linear infinite backwards;

  animation-name: swipe;
  animation-duration: 25500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: backwards;

  -webkit-animation-name: swipe;
  -webkit-animation-duration: 25500ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: backwards;

  -moz-animation-name: swipe;
  -moz-animation-duration: 25500ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -moz-animation-fill-mode: backwards;

  -o-animation-name: swipe;
  -o-animation-duration: 25500ms;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
  -o-animation-fill-mode: backwards;
}

@keyframes swipe {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
}

@-webkit-keyframes swipe {
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
}

@-moz-keyframes swipe {
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
}

@-o-keyframes swipe {
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
}